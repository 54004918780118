<template>
  <v-app>
    <Menu
      v-if="!isPreview"
      :session="session"
    />

    <Header
      v-if="!isPreview"
      :token="token"
    />

    <v-main>
      <v-container fluid>
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            md11
            xl10
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view v-if="token" />
            </transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <Footer
      :token="token"
      :session="session"
    />
  </v-app>
</template>

<script>
import pkg from '../../../package.json'
import { getToken, getSession } from '@/utils'
import Menu from '@/components/Menu'
import Footer from '@/components/Footer'
import Header from '@/components/Header'

export default {
  components: {
    Menu,
    Footer,
    Header
  },

  data () {
    return {
      version: pkg.version,
      token: null,
      stream: {},
      session: {}
    }
  },

  mounted () {
    this.loadSession()
    setInterval(() => this.loadSession(), 1000 * 60 * 5)
  },

  computed: {
    isPreview () {
      return ['true', 't', 'yes', 'y', '1'].includes(this.$route?.query.preview)
    }
  },

  methods: {
    loadSession () {
      getToken().then((token) => (
        getSession().then((session) => (
          Object.assign(this, { session, token })
        ))
      ))
    }
  }
}
</script>
