export default {
  noDataText: 'Sem informação',

  'Blocked Session': 'Acesso restrito ao Quadro de Mestres',
  'Expired Session': 'Acesso com tempo expirado',
  'Finished Session': 'Acesso realizado em outro dispositivo',

  'Incorrect username or password.': 'Senha Inválida',
  'User account already exists': 'Sócio já se encontra cadastrado',
  'An account with the given email already exists.': 'Sócio já se encontra cadastrado',
  'sended email with temporary password': 'Foi disparado um e-mail com uma senha temporária.',
  'resended code to the phone': 'Código reencaminhado',
  'affiliate not found': 'Email não vinculado a um usuário do Reuni. Para se cadastrar no sistema favor informar o e-mail cadastrado no Reuni',
  'internal error': 'Ocorreu um erro no servidor! Por favor Tente novamente ou avise o suporte.',
  'Invalid code or auth state for the user.': 'Código Inválido',
  'User does not exist.': 'Sócio não cadastrado',
  'unfound affiliate': 'Email não vinculado a um usuário do Reuni. Para se cadastrar no sistema favor informar o e-mail cadastrado no Reuni',
  'invalid degree': 'Grau de Sócio não autorizado',
  'Expired code received for user': 'Código de confirmação expirado, tente fazer o login novamente',
  'Username/client id combination not found.': 'Sócio não cadastrado',
  'User password cannot be reset in the current state.': 'Esse cadastro não foi confirmado ainda.',
  unauthorized: 'Não autorizado',
  'invalid phone': 'O telefone do usuário não está cadastrado corretamente no Reuni. Favor solicitar ao secretário de seu Núcleo que cadastre seu número de telefone no campo Whatsapp do Reuni.',

  filter: {
    all: 'Todos',
    sync: 'Digite o nome',
    data: 'Data',
    grau: 'Grau',
    nucleo: 'Núcleo',
    orador: 'Orador',
    assunto: 'Assunto',
    dirigente: 'Dirigente',
    revisor: 'Revisor',
    supervisor: 'Supervisor',
    userArg: 'Nome/E-mail',
    review: 'Revisão',
    tipo: 'Tipo do Áudio',
    sessao: 'Sessão',
    learnDescriptionArg: 'Por descrição',
    learnSearchArg: 'Por conteúdo',
    albumName: 'Nome do Álbum'
  },

  drag: {
    AudiosAtualizacoes: {
      contagem: 'Contagem de Revisões',
      action: 'Ação',
      created_at: 'Momento da Atualização',
      payload: 'Modificação'
    },

    Acessos: {
      created_at: 'Data/Hora',
      os: 'OS',
      device: 'Dispositivo',
      application: 'Aplicação',

      contagem: 'Contagem de Acessos',
      primeiro: 'Primeiro Acesso',
      derradeiro: 'Acesso Recente'
    },

    Pesquisas: {
      created_at: 'Período',
      pesquisa: 'Pesquisa',

      contagem: 'Contagem de Pesquisas',
      primeira: 'Primeira Pesquisa',
      derradeira: 'Pesquisa Recente'
    },

    Tokens: {
      os: 'OS',
      device: 'Dispositivo',
      application: 'Aplicação'
    },

    Navegacoes: {
      created_at: 'Período',
      evento: 'Visualização',
      payload: 'Nome',
      contagem: 'Contagem de Visualizações'
    },

    FaixaActions: {
      contagem: 'Contagem de Eventos',
      action: 'Evento',
      created_at: 'Data/Hora Evento'
    },

    Faixas: {
      assunto: 'Assunto',
      resumo: 'Resumo',
      palavras: 'Palavras chaves',
      created_at: 'Data do cadastro',
      revisao_status: 'Situação da Revisão',
      revisao_data: 'Data da Revisão',
      transcricao: 'Transcrição',
      mestre_disse: 'Mestre disse',
      data: 'Data Áudio',

      contagem: 'Contagem Acervo',
      size: 'Tamanho Arquivo',
      duracao: 'Tempo Reprodução'
    },

    AudiosTipos: {
      descricao: 'Tipo do Áudio'
    },

    Sessoes: {
      descricao: 'Sessão'
    },

    Albums: {
      nome: 'Nome',
      resumo: 'Resumo',
      descricao: 'Descrição'
    },

    Dirigentes: {
      nome: 'Dirigente',
      mgr: 'Dirigente MGR',
      antigo: 'Dirigente da Origem'
    },

    Oradores: {
      nome: 'Orador',
      mgr: 'Orador MGR',
      antigo: 'Orador da Origem'
    },

    Regioes: {
      nome: 'Região'
    },

    Nucleos: {
      nome: 'Núcleo'
    },

    Perfis: {
      nome: 'Perfil'
    },

    Graus: {
      nome: 'Grau'
    },

    Users: {
      name: 'Usuário'
    }
  },

  drop: {
    Acessos: {
      created_at: 'Período',
      os: 'OS',
      device: 'Dispositivo',
      application: 'Aplicação',

      contagem: 'Contagem de Acessos',
      primeiro: '1º Acesso',
      derradeiro: 'Acesso Recente'
    },

    AudiosAtualizacoes: {
      contagem: 'Contagem de Revisões',
      action: 'Ação',
      created_at: 'Momento da Atualização',
      payload: 'Modificação'
    },

    Pesquisas: {
      created_at: 'Período',
      pesquisa: 'Pesquisa',

      contagem: 'Contagem',
      primeira: '1ª Pesquisa',
      derradeira: 'Pesquisa Recente'
    },

    Tokens: {
      os: 'OS',
      device: 'Dispositivo',
      application: 'Aplicação'
    },

    Navegacoes: {
      created_at: 'Período',
      evento: 'Acesso',
      payload: 'Área',
      contagem: 'Acessos'
    },

    FaixaActions: {
      contagem: 'Eventos',
      action: 'Evento',
      created_at: 'Data/Hora'
    },

    Faixas: {
      assunto: 'Assunto',
      resumo: 'Resumo',
      palavras: 'Palavras',
      created_at: 'Cadastro',
      revisao_status: 'Situação da Revisão',
      revisao_data: 'Data da Revisão',
      transcricao: 'Transcrição',
      mestre_disse: 'Mestre disse',
      data: 'Data Áudio',

      contagem: 'Contagem',
      size: 'Tamanho Arquivo',
      duracao: 'Tempo Reprodução'
    },

    AudiosTipos: {
      descricao: 'Categoria'
    },

    Sessoes: {
      descricao: 'Sessão'
    },

    Albums: {
      nome: 'Álbum',
      resumo: 'Resumo Álbum',
      descricao: 'Descrição Álbum'
    },

    Dirigentes: {
      nome: 'Dirigente',
      mgr: 'Dirigente MGR',
      antigo: 'Dirigente da Origem'
    },

    Oradores: {
      nome: 'Orador',
      mgr: 'Orador MGR',
      antigo: 'Orador da Origem'
    },

    Regioes: {
      nome: 'Região'
    },

    Nucleos: {
      nome: 'Núcleo'
    },

    Perfis: {
      nome: 'Perfil'
    },

    Graus: {
      nome: 'Grau'
    },

    Users: {
      name: 'Usuário'
    }
  },

  invalid: {
    email: 'E-mail inválido',
    password: 'Senha não confere'
  },

  reports: {
    menu: 'Relatórios',
    audios: 'Acervo',
    search: 'Pesquisas',
    access: 'Acessos',
    navigation: 'Mobile',
    reviews: 'Revisões',
    audiosTitle: 'Relatório do Acervo',
    reviewTitle: 'Relatório de Revisões',
    searchTitle: 'Relatório de Pesquisas',
    accessTitle: 'Relatório de Acessos',
    navigationTitle: 'Relatório de Navegação Mobile'
  },

  title: {
    app: 'Resgate de Memória',
    auth: 'Credenciais',
    recovery: 'Recuperar Acesso',
    firstAccess: 'Cadastrar',
    invite: 'Convite',
    changePassword: 'Alterar senha',
    forceNewPassword: 'Nova Senha',
    confirm: 'Confirmar {method}',
    searchAudios: 'Buscar no Acervo',

    dashboardTitle: 'Dashboard',
    dashboardLabel: '',

    audiosApprovedTitle: 'Acervo',
    audiosApprovedLabel: 'Aprovado',

    audiosReviewTitle: 'Aguardando',
    audiosReviewLabel: 'Revisão',

    audiosReview2Title: 'Parcialmente',
    audiosReview2Label: 'Revisados',

    audiosApprovingTitle: 'Aguardando',
    audiosApprovingLabel: 'Aprovação',

    audiosExamTitle: 'Para',
    audiosExamLabel: 'Exame',

    newAudios: 'Inserir Acervo',
    newAudiosTitle: 'Inserir',
    newAudiosLabel: 'Acervo',

    listAlbums: 'Álbums',

    newAlbums: 'Inserir Álbuns',
    newAlbum: 'Cadastrar Álbum',
    updateAlbum: 'Atualizar Álbum',
    newAlbumsTitle: 'Ver',
    newAlbumsLabel: 'Álbuns',

    newUpdates: 'Cadastrar Atualizações',
    newUpdatesTitle: 'Cadastrar',
    newUpdatesLabel: 'Atualizações ',
    newUpdatesImprovements: 'e Melhorias',

    listUsers: 'Usuários',
    audioReview: 'Revisar Áudio',

    newLearnPage: 'Criar Página',
    newLearnTitle: 'Páginas',
    newLearnLabel: 'de Ensinos'
  },

  btn: {
    version: 'Versão do sistema',
    addAudios: 'Adicionar novos áudios',
    exit: 'Sair do sistema',

    recovery: 'Recuperar',
    register: 'Cadastrar',
    access: 'Acessar',
    back: 'Voltar',
    save: 'Salvar',
    confirm: 'Confirmar',
    logout: 'Sair',
    renew: 'Voltar'
  },

  required: {
    email: 'E-mail é obrigatório',
    password: 'Senha é obrigatória',
    length: 'mínimo de {length} caracteres',
    core: 'Núcleo é obrigatório',
    code: 'Código é obrigatório'
  },

  field: {
    cpfDesc: 'Código Pessoa Física',
    cpfCode: 'CPF',

    newPassword: 'Nova senha',
    confirmPassword: 'Confirmação da senha',

    msgSMS: 'Celular',
    msgEMAIL: 'E-mail',
    msgundefined: 'Identificado',
    code: 'Código de confirmação',
    grau: 'Grau',

    username: 'Acesso',
    temporaryPassword: 'Senha temporária',

    core: 'Núcleo',
    email: 'E-mail',
    phone: 'Celular',
    password: 'Senha'
  }
}
