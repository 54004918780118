import Dashboard from '@/views/Dashboard'

import {
  ROUTE_LEARN_PAGES,
  ROUTE_USERS_SEARCH,

  ROUTE_AUDIOS_NEW,
  ROUTE_AUDIO_REVIEW,

  ROUTE_AUDIOS_APPROVED,
  ROUTE_AUDIOS_REVIEW,
  ROUTE_AUDIOS_REVIEW2,
  ROUTE_AUDIOS_APPROVING,
  ROUTE_AUDIOS_EXAM,

  ROUTE_LEARN_PAGES_NEW,
  ROUTE_LEARN_PAGES_EDIT,
  ROUTE_LEARN_PAGES_VIEW,
  ROUTE_LEARN_PAGES_TMP,

  ROUTE_ALBUMS_NEW,
  ROUTE_ALBUMS_LIST,
  ROUTE_ALBUMS_EDIT,
  ROUTE_ALBUMS_VIEW,

  ROUTE_UPDATES_NEW,
  ROUTE_UPDATES_LIST,
  ROUTE_UPDATES_EDIT,

  ROUTE_DASHBOARD_CHARTS
} from '@/constants'

const UserSearch = () => import(/* webpackChunkName: "dash-usersearch" */ '../views/Dashboard/UserSearch')
const LearnPageForm = () => import(/* webpackChunkName: "dash-learn-page-form" */ '../views/Dashboard/LearnPageForm')
const LearnPageView = () => import(/* webpackChunkName: "dash-learn-page-form" */ '../views/Dashboard/LearnPageView')
const LearnPageSearch = () => import(/* webpackChunkName: "dash-learn-page-search" */ '../views/Dashboard/LearnPageSearch')

const AudioForm = () => import(/* webpackChunkName: "dash-audio-form" */ '../views/Dashboard/AudioForm')
const AudioReview = () => import(/* webpackChunkName: "dash-audio-review" */ '../views/Dashboard/AudioReview')
const AudioSearchApproved = () => import(/* webpackChunkName: "audio-approveds" */ '../views/Dashboard/AudioSearchApproved')
const AudioSearchApproving = () => import(/* webpackChunkName: "audio-approving" */ '../views/Dashboard/AudioSearchApproving')
const AudioSearchExam = () => import(/* webpackChunkName: "audio-exam" */ '../views/Dashboard/AudioSearchExam')
const AudioSearchReview = () => import(/* webpackChunkName: "audio-review" */ '../views/Dashboard/AudioSearchReview')
const AudioSearchReview2 = () => import(/* webpackChunkName: "audio-review2" */ '../views/Dashboard/AudioSearchReview2')

const UpdatesForm = () => import(/* webpackChunkName: "dash-updates-form" */ '../views/Dashboard/UpdatesForm')
const UpdatesList = () => import(/* webpackChunkName: "dash-updates-list" */ '../views/Dashboard/UpdatesList')

const AlbumsList = () => import(/* webpackChunkName: "dash-albums-list" */ '../views/Dashboard/AlbumsList')
const AlbumsForm = () => import(/* webpackChunkName: "dash-albums-form" */ '../views/Dashboard/AlbumsForm')
const DashboardCharts = () => import(/* webpackChunkName: "dash-dashboard-charts" */ '../views/Dashboard/DashboardCharts')

export default {
  path: '/',
  redirect: { name: ROUTE_AUDIOS_APPROVED },
  component: Dashboard,
  children: [{
    path: '/audios-aprovados',
    name: ROUTE_AUDIOS_APPROVED,
    component: AudioSearchApproved
  }, {
    path: '/aguardando-revisao',
    name: ROUTE_AUDIOS_REVIEW,
    component: AudioSearchReview
  }, {
    path: '/parcialmente-revisados',
    name: ROUTE_AUDIOS_REVIEW2,
    component: AudioSearchReview2
  }, {
    path: '/aguardando-aprovacao',
    name: ROUTE_AUDIOS_APPROVING,
    component: AudioSearchApproving
  }, {
    path: '/para-exame',
    name: ROUTE_AUDIOS_EXAM,
    component: AudioSearchExam
  }, {
    path: '/audios/:id/revisao',
    name: ROUTE_AUDIO_REVIEW,
    component: AudioReview
  }, {
    path: '/audios/cadastrar',
    name: ROUTE_AUDIOS_NEW,
    component: AudioForm
  }, {
    path: '/albums',
    name: ROUTE_ALBUMS_LIST,
    component: AlbumsList
  }, {
    path: '/albums/adicionar',
    name: ROUTE_ALBUMS_NEW,
    component: AlbumsForm
  }, {
    path: '/albums/:id/editar',
    name: ROUTE_ALBUMS_EDIT,
    component: AlbumsForm
  }, {
    path: '/albums/:id/view',
    name: ROUTE_ALBUMS_VIEW,
    component: AlbumsForm
  }, {
    path: '/usuarios',
    name: ROUTE_USERS_SEARCH,
    component: UserSearch
  }, {
    path: '/paginas-ensinos',
    name: ROUTE_LEARN_PAGES,
    component: LearnPageSearch
  }, {
    path: '/paginas-ensinos/adicionar',
    name: ROUTE_LEARN_PAGES_NEW,
    component: LearnPageForm
  }, {
    path: '/paginas-ensinos/:id/editar',
    name: ROUTE_LEARN_PAGES_EDIT,
    component: LearnPageForm
  }, {
    path: '/paginas-ensinos/visualizar/:id/:descricao',
    name: ROUTE_LEARN_PAGES_VIEW,
    component: LearnPageView
  }, {
    path: '/paginas-ensinos/pre-visualizar',
    name: ROUTE_LEARN_PAGES_TMP,
    component: LearnPageView
  }, {
    path: '/atualizacoes/cadastrar',
    name: ROUTE_UPDATES_NEW,
    component: UpdatesForm
  }, {
    path: '/atualizacoes/:id/editar',
    name: ROUTE_UPDATES_EDIT,
    component: UpdatesForm
  }, {
    path: '/atualizacoes/',
    name: ROUTE_UPDATES_LIST,
    component: UpdatesList
  }, {
    path: '/dashboards',
    name: ROUTE_DASHBOARD_CHARTS,
    component: DashboardCharts
  }]
}
