import store from '@/store'
import { API } from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'

import { ACTION_USER_LOGOUT } from '@/constants'

export const getHostAPI = () => {
  const { hostname } = window.location
  return process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : `https://api.${hostname}/v1`
}

Auth.configure({
  region: process.env.VUE_APP_REGION,
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'CUSTOM_AUTH'
})

API.configure({
  endpoints: [{
    name: 'api',
    endpoint: getHostAPI(),

    custom_header: () => {
      const token = localStorage.getItem('token') || ''
      return token ? { Authorization: `Bearer ${token}` } : ''
    }
  }, {
    name: 'local',
    endpoint: 'http://0.0.0.0:3000/sandbox',

    custom_header: () => {
      const token = localStorage.getItem('token') || ''
      return token ? { Authorization: `Bearer ${token}` } : ''
    }
  }]
})

const apiEnv = 'api'

export const requests = {
  getCores: (query) => API.get(apiEnv, '/nucleos', { queryStringParameters: query }),
  getOradores: (query) => API.get(apiEnv, '/oradores', { queryStringParameters: query }),

  postAlbum: (body) => API.post(apiEnv, '/albums', { body }).catch(checkErrorUnauthorized),
  putAlbum: (id, body) => API.put(apiEnv, `/albums/${id}`, { body }).catch(checkErrorUnauthorized),
  getAlbum: (id) => API.get(apiEnv, `/albums/${id}`),
  getAlbums: (query) => API.get(apiEnv, '/albums', { queryStringParameters: query }),
  deleteAlbum: (id) => API.put(apiEnv, `/albums/${id}/remove`).catch(checkErrorUnauthorized),
  getImageUploadUrl: (query) => API.get(apiEnv, '/albums/signed-link/upload', { queryStringParameters: query }),

  getLearnPage: (id) => API.get(apiEnv, `/learn-pages/${id}`),
  getLearnPages: (query) => API.get(apiEnv, '/learn-pages', { queryStringParameters: query }),
  putLearnPage: (id, body) => API.put(apiEnv, `/learn-pages/${id}`, { body }),
  postLearnPage: (body) => API.post(apiEnv, '/learn-pages', { body }),

  getUpdate: (id) => API.get(apiEnv, `/updates/${id}`),
  getUpdates: (query) => API.get(apiEnv, '/updates', { queryStringParameters: query }),
  putUpdate: (id, body) => API.put(apiEnv, `/updates/${id}`, { body }).catch(checkErrorUnauthorized),
  postUpdate: (body) => API.post(apiEnv, '/updates', { body }).catch(checkErrorUnauthorized),
  deleteUpdate: (id) => API.put(apiEnv, `/updates/remove/${id}`).catch(checkErrorUnauthorized),
  resendCode: (body) => API.post(apiEnv, '/authentication/code', { body }),

  postAudio: (body) => API.post(apiEnv, '/audios', { body }),
  getAudioUploadLink: ({ size, extension }) => API.get(apiEnv, '/audios/signedlink', { queryStringParameters: { extension, length: size } }),
  getAudioStreamLink: ({ id }) => API.get(apiEnv, `/audios/${id}/streamlink`),

  getAudio: (id) => API.get(apiEnv, `/audios/${id}/metadata`).catch(checkErrorUnauthorized),
  getAudios: (query) => API.get(apiEnv, '/audios', { queryStringParameters: query }).catch(checkErrorUnauthorized),
  setMetadata: (id, body) => API.put(apiEnv, `/audios/${id}/metadata`, { body }).catch(checkErrorUnauthorized),
  deleteAudio: (id) => API.put(apiEnv, `/audios/${id}/remove`).catch(checkErrorUnauthorized),

  addUser: (body) => API.post(apiEnv, '/users', { body }),
  getUsers: (query) => API.get(apiEnv, '/users', { queryStringParameters: query }).catch(checkErrorUnauthorized),
  setUserProfile: (user, profile) => API.post(apiEnv, `/users/${user}/profile/${profile}`),
  removeUserProfile: (user, profile) => API.put(apiEnv, `/users/${user}/profile/${profile}`),

  getRegions: (query) => API.get(apiEnv, '/regioes', { queryStringParameters: query }),
  getAccessReport: (query) => API.get(apiEnv, '/reports/access', { queryStringParameters: query }),

  getCubeFilters: () => API.get(apiEnv, '/etl/v1/filters'),
  refreshAppToken: () => API.get(apiEnv, '/refresh')
}

export default API
export const cognito = () => Auth

const checkErrorUnauthorized = (err) => {
  const hasErrorApi = Boolean(err && err.response && err.response.data && err.response.data.error)
  if (hasErrorApi) return store.dispatch(ACTION_USER_LOGOUT, err.response.data.error)
  throw err
}
