import jwt from 'jsonwebtoken'
import { parse } from 'node-html-parser'
import { requests } from '@/plugins/Amplify'

const minute = 60
const hour = 60 * minute

const getUnits = (seconds) => ([
  { sufix: ':', amount: Math.trunc((seconds % hour) / minute), print: true, pad: 2 },
  { sufix: '', amount: Math.trunc(seconds % minute), print: true, pad: 2 }
])

const padStartAmount = (unit) => {
  unit.amount = String(unit.amount).padStart(unit.pad, '0')
  return unit
}

export const formatSecs = (secs) => (
  secs
    ? getUnits(Number(secs))
      .filter(unit => unit.print)
      .map(padStartAmount)
      .reduce((acc, unit) => `${acc}${unit.amount}${unit.sufix}`, '')
      .trim()
    : ''
)

export const getToken = async () => {
  const token = String(localStorage.getItem('token') || '').trim()
  if (!token) return null

  try {
    const now = Math.floor(Date.now() / 1000)
    const { iat, exp } = jwt.decode(token)
    const expired = !exp || (now >= exp)

    if (expired) {
      localStorage.clear()
      return null
    }

    const notUpdate = (iat + 3600) > now
    if (notUpdate) return token

    const res = await requests.refreshAppToken()
    localStorage.setItem('token', res.token)
    return res.token
  } catch (_) {
    return token
  }
}

export const getSession = async () => {
  const token = await getToken()
  if (!token) return null

  try {
    const session = jwt.decode(token)
    return session
  } catch (_) {
    return null
  }
}

export const parseHTML = (str) => {
  if (!str) return str
  const root = parse(`<div>${str}</div>`).getElementsByTagName('div')[0]

  root.querySelectorAll('figure.media').forEach((figure) => {
    const dmcOembed = figure.querySelectorAll('oembed').find((oembed) => {
      const url = oembed.getAttribute('url')
      const isDmcVivo = url.includes('.udv.org.br/v1/audios/')
      const isHomolog = url.includes('.nurimba.com/v1/audios/')
      return isDmcVivo || isHomolog
    })

    if (dmcOembed) {
      figure.replaceWith(parse(`<audio style="position: relative" src="${dmcOembed.getAttribute('url')}" controls preload></audio>`))
    }
  })

  return root.toString()
}
